import "../assets/SCSS/pages/resource-document.scss";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import {
  getResourceDocumentsList,
  addNewResourceDocument,
  editResourceDocument,
  deleteResourceDocument,
  updateCardSequence,
} from "../axios/apicall";
import AddNewResourceDocumentModal from "../components/AddNewResourceDocumentModal";
import { toast } from "react-toastify";
import EditResourceDocumentModal from "../components/EditResourceDocumentModal";
import Swal from "sweetalert2";
import Select from "react-select";

export const ResourceDocument = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [resourceDocuments, setResourceDocuments] = useState([]);
  const [isAddResourceDocumentModalOpen, setIsAddResourceDocumentModalOpen] =
    useState(false); // State to control modal visibility
  const [isEditResourceDocumentModalOpen, setIsEditResourceDocumentModalOpen] =
    useState(false); // State to control modal visibility
  const [currentDocumentData, setCurrentDocumentData] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);
  const [filter, setFilter] = useState({});

  const fetchResourceDocuments = async () => {
    setShowLoader(true);
    try {
      const response = await getResourceDocumentsList({ ...filter });
      if (response.data.status === "success") {
        setResourceDocuments(response.data.data);
      } else {
        toast.error("Failed to fetch resource documents");
      }
    } catch (error) {
      toast.error("Error fetching resource documents");
    } finally {
      setShowLoader(false);
    }
  };

  const deleteDocument = async (id) => {
    setShowLoader(true);
    try {
      const response = await deleteResourceDocument(id);
      if (response.data.status === "success") {
        toast.success("Deleted resource document successfully");
        const resourceDocumentsUpdated = resourceDocuments.filter(
          (record) => record.id !== id
        );
        setResourceDocuments(resourceDocumentsUpdated);
      } else {
        toast.error(
          `Failed to delete resource document, error: ${response.data.message}`
        );
      }
    } catch (error) {
      toast.error("Error deleting resource document");
    } finally {
      setShowLoader(false);
    }
  };

  const addResourceDocument = async (newCardData) => {
    setShowLoader(true);
    const { file, uploadFileName, imageFile, uploadImageFileName } =
      newCardData; // Destructure to get image file data

    const formData = new FormData();
    formData.append("fileName", newCardData.fileName);
    formData.append("fileType", newCardData.fileType);
    formData.append("visibleTo", newCardData.visibleTo);
    formData.append("isActive", newCardData.isActive);

    if (imageFile) {
      // Append the raw file to FormData
      formData.append("image", imageFile, uploadImageFileName);
    }
    if (file) {
      // Append the raw file to FormData
      formData.append("file", file, uploadFileName);
    }
    try {
      const response = await addNewResourceDocument(formData);
      if (response.data.status === "success") {
        toast.success("Added new resource document successfully");
        fetchResourceDocuments(); // Refresh the cards list after adding
      } else {
        toast.error(
          `Failed to add resource document, error: ${response.data.message}`
        );
      }
    } catch (error) {
      toast.error(
        "Error adding resource document, error: ",
        response.data.message
      );
    } finally {
      setShowLoader(false);
    }
  };

  const editCard = async (editCardData) => {
    setShowLoader(true);
    const {
      id,
      fileName,
      fileType,
      visibleTo,
      file,
      uploadFileName,
      imageFile,
      uploadImageFileName,
      isActive,
    } = editCardData;

    const formData = new FormData();
    formData.append("id", id);
    if (fileName) formData.append("fileName", fileName);
    if (fileType) formData.append("fileType", fileType);
    if (visibleTo) formData.append("visibleTo", visibleTo);
    if (isActive !== undefined) formData.append("isActive", isActive);

    console.log({ imageFile, file });
    if (imageFile) {
      // Append the raw image file to FormData
      formData.append("image", imageFile, uploadImageFileName);
    }

    if (file) {
      // Append the raw file to FormData
      formData.append("file", file, uploadFileName);
    }
    try {
      const response = await editResourceDocument(formData);
      if (response.data.status === "success") {
        toast.success("Updated resource document successfully");
        fetchResourceDocuments(); // Refresh the cards list after adding
      } else {
        toast.error(
          `Failed to update resource document, error: ${response.data.message}`
        );
      }
    } catch (error) {
      toast.error(
        "Error updating resource document, error: ",
        response.data.message
      );
    } finally {
      setShowLoader(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    fetchResourceDocuments();
  }, [filter]);

  const deleteResourceDocumentHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocument(id);
      }
    });
  };

  const editResourceDocumentHandler = (data) => {
    setIsEditResourceDocumentModalOpen(true);
    setCurrentDocumentData(data);
  };

  // Drag and Drop handlers
  const handleDragStart = (e, record) => {
    setDraggedItem(record);
    e.currentTarget.style.opacity = "0.4";
    e.dataTransfer.effectAllowed = "move";
  };
  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
    e.currentTarget.classList.remove("over");
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };
  const handleDragEnter = (e) => {
    e.currentTarget.classList.add("over");
  };
  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove("over");
  };
  const handleDrop = async (e, targetRecord) => {
    e.preventDefault();
    e.currentTarget.classList.remove("over");

    if (!draggedItem || draggedItem.id === targetRecord.id) return;
    const dragIndex = resourceDocuments.findIndex(
      (item) => item.id === draggedItem.id
    );
    const dropIndex = resourceDocuments.findIndex(
      (item) => item.id === targetRecord.id
    );
    // Create new array with reordered items
    const newCards = [...resourceDocuments];
    newCards.splice(dragIndex, 1);
    newCards.splice(dropIndex, 0, draggedItem);
    // Update sequence numbers
    const updatedCards = newCards.map((card, index) => ({
      ...card,
      sequence: index + 1,
    }));
    setResourceDocuments(updatedCards);
    // Send sequence update to backend
    try {
      setShowLoader(true);
      const sequenceData = updatedCards.map((card) => ({
        id: card.id,
        seqNo: card.sequence,
      }));
      const response = await updateCardSequence({ sequenceData }, "resource");

      if (response.data.status === "success") {
        toast.success("Card sequence updated successfully");
      } else {
        toast.error("Failed to update card sequence");
        fetchResourceDocuments();
      }
    } catch (error) {
      toast.error("Error updating card sequence");
      fetchResourceDocuments();
    } finally {
      setShowLoader(false);
    }
  };

  const userTypeOptions = [
    { value: null, label: "All" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
  ];

  const statusOptions = [
    { value: null, label: "All" },
    { value: true, label: "Active" },
    { value: false, label: "In-Active" },
  ];

  document.title = "Education Documents";

  return (
    <>
      <main className="page">
        <div className="flex justify-between mb-3">
          <h3 className="mb-0 text-primary">Education Documents</h3>
          <div className="flex justify-between">
            <div className="user-type">
              <Select
                className="dropdown"
                options={userTypeOptions}
                placeholder="Select User Type"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    visibleTo:
                      e.value === "Enrolled" || e.value === "Pre-Enrolled"
                        ? e.value
                        : null,
                  });
                }}
              />
            </div>
            <div className="status">
              <Select
                className="dropdown"
                options={statusOptions}
                placeholder="Select Status"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    isActive:
                      e.value === true || e.value === false ? e.value : null,
                  });
                }}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => setIsAddResourceDocumentModalOpen(true)} // Open modal on click
            >
              Add +
            </button>
          </div>
        </div>
        <div className="row align-start">
          <div className="content full">
            <Loader show={showLoader} />
            <div className="table-wrapper">
              <table className="resource-document-table table table-hover">
                <thead>
                  <tr>
                    <th className="bg-primary text-white">File Name</th>
                    <th className="bg-primary text-white">Link</th>
                    <th className="bg-primary text-white">File Type</th>
                    <th className="bg-primary text-white">Thumbnail</th>
                    <th className="bg-primary text-white">Visible To</th>
                    <th className="bg-primary text-white">Status</th>
                    <th className="bg-primary text-white">Created At</th>
                    <th className="bg-primary text-white">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {resourceDocuments.map((record) => (
                    <tr
                      key={record.id}
                      draggable={true}
                      onDragStart={(e) => handleDragStart(e, record)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, record)}
                      className="cursor-move"
                    >
                      <td>{record.fileName}</td>
                      <td>
                        {record.en_link ? (
                          <a
                            href={record.en_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="decoration-none"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{record.fileType}</td>
                      <td>
                        {record.thumbnail_link ? (
                          <a
                            href={record.thumbnail_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="decoration-none"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{record.visibleTo}</td>
                      <td>
                        <div
                          className={
                            record.isActive
                              ? "is-active-true"
                              : "is-active-false"
                          }
                        >
                          {record.isActive ? "Active" : "Inactive"}
                        </div>
                      </td>
                      <td>{formatDate(record.createdAt)}</td>
                      <td>
                        <div className="flex justify-center">
                          <button
                            className="btn-icon"
                            onClick={() => editResourceDocumentHandler(record)} // Open modal on click
                          >
                            <i className="icon-edit"></i>
                          </button>
                          <button
                            className="btn-icon"
                            onClick={() =>
                              deleteResourceDocumentHandler(record.id)
                            }
                          >
                            <i className="icon-delete"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      {/* Add New Card Modal */}
      {isAddResourceDocumentModalOpen && (
        <AddNewResourceDocumentModal
          onClose={() => setIsAddResourceDocumentModalOpen(false)} // Close modal handler
          onSubmit={addResourceDocument} // Submit handler
        />
      )}

      {isEditResourceDocumentModalOpen && (
        <EditResourceDocumentModal
          onClose={() => setIsEditResourceDocumentModalOpen(false)} // Close modal handler
          onSubmit={editCard} // Submit handler
          currentDocumentData={currentDocumentData}
        />
      )}
    </>
  );
};
