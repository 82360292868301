import React, { useEffect, useState } from "react";
import { downloadSampleFile, exportUsersDetails, getAllEmployees, getUsersSummary, importFile } from "../axios/apicall";
import Loader from "../components/Loader";
import NoData from "../components/NoData";
import "../assets/SCSS/pages/employees.scss";
import "../assets/SCSS/pages/file-upload-popup.scss";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PopupImportFile from "./PopupImportFile";

const Employees = () => {
  const Navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [totalCounts, setTotalCounts]= useState();
  const [summaryData, setSummaryData] = useState(null);
  let itemsPerPage = 50;
  const pageCount = Math.ceil(employeeCount / itemsPerPage);
  const maxDisplayedPages = 5;
  const displayedPages = Math.min(pageCount, maxDisplayedPages);

  const [filter, setFilter] = useState({
    userStatus: null,
    userIsSignedUp: null,
    search: null,
    page: 1,
  });

  useEffect(()=>{
    getEmployeeSummary();
  },[]);

  useEffect(() => {
    setShowLoader(true);
    getAllEmployees(filter)
    .then((res) => {
      setShowLoader(false);
      setTotalCounts(res.data.data?.count);
      if (res.data.status === "success") {
          setEmployeeCount(res.data.data.count);
          setEmployees(res.data.data.userDetails);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false);
      });
  }, [filter]);

  document.title = "Employees";

  const getEmployeeSummary = () =>{
    getUsersSummary()
      .then((res) => {
        if (res.status === 200) {
          const { totalEmployeesCount, totalRegisteredEmployees, totalNonRegisteredEmployees, totalInactiveEmployees } = res?.data?.data;
          setSummaryData({ totalEmployeesCount, totalRegisteredEmployees, totalNonRegisteredEmployees, totalInactiveEmployees });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false)
      });
  }

  const handlePageClick = (page) => {
    setFilter({ ...filter, page: page.selected + 1 });
  };

  const options = [
    { value: null, label: "All" },
    { value: "1", label: "Active" },
    { value: "0", label: "In Active" },
    { value: "2", label: "Registered" },
    { value: "3", label: "Not Registered" }
  ];

  function handleSearchChange(e) {
    e.preventDefault();
    let val = document.querySelector('[name=search-employees]').value;

    if (val !== undefined) {
      let sentence = val.trim();

      let words = sentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        let capitalized =
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        words[i] = capitalized;
      }

      let inputValue = words.join(" ");
      if (inputValue.length >= 2) {
        setFilter({ ...filter, page: 1, search: inputValue });
      } else if (inputValue.length < 3) {
        setFilter({ ...filter, page: 1, search: null });
        setSearch("");
      } else {
        setFilter({ ...filter, page: 1, search: null });
        setSearch("");
      }
    }
  }
  
  const paginationLength =
    filter.page <= displayedPages || filter.page > pageCount - displayedPages + 1
      ? Math.min(displayedPages, pageCount)
      : maxDisplayedPages;

  const ShowPopupHandler = () =>{
    setShowPopup(true);
  }
  const closePopUpHandler = () =>{
     setShowPopup(false);
    setShowLoader(false);
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) { 
      const fileExtension = selectedFile.name.split('.').pop();

      if (fileExtension !== 'csv') {
        toast.error("Unsupported file format.");
        return false;
      }
      if (selectedFile.size > 100 * 1024 * 1024) { // 100 MB in bytes
        toast.error("File size exceeds the maximum allowed limit (100 MB).");
        return false;
      }
      setShowLoader(true);   
      const fileUrl = URL.createObjectURL(selectedFile);
      setSelectedFileUrl(fileUrl);

      const form_data = new FormData();
      form_data.append('userData', selectedFile);

       importFile(form_data)
       .then((res) => {
         setSelectedFile(null)
        if (res.data.status_code === 400) {
          toast.error(res.data.message);
          localStorage.setItem('error-imported-employees', JSON.stringify(res.data.data));
          setShowPopup(false);
          Navigate(`/employees/error_imported`);
        }
        if(res.data.status_code === 200){
          toast.success(res.data.message); 
          setShowLoader(false);
          localStorage.setItem('imported-employees', JSON.stringify(res.data.data?.importedRecords));
          localStorage.setItem('imported-ignored-records', JSON.stringify(res.data.data.ignoredRecords));
          Navigate(`/employees/imported`);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false);
      });
    }
  };
  const DownloadFileHandler = () =>{
     downloadSampleFile()
      .then((res) => {
        if(res.status === 200){
           const sampleFileData = res.data; // This should contain your sample file data
          // Create a Blob object from the file data
          const blob = new Blob([sampleFileData], { type: "text/csv;charset=utf-8" });
          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
        // Create an anchor element
          const anchor = document.createElement("a");
          anchor.download = "sample-file";
          anchor.href = blobUrl;
        // Programmatically click the anchor to trigger the download
          anchor.click();
          // Clean up the URL created for the Blob
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setShowLoader(false)
      });
  }

  const exportUserClickHandler = () =>{
    exportUsersDetails({
      isRegistered: filter.userIsSignedUp,
      userStatus: filter.userStatus
    })
    .then((res)=>{
      if(res.status === 200){

        const today = new Date();
        const formatted_date = `${today.getDate().toString().padStart(2, "0")}_${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}_${today.getFullYear()}`;
        

        let fileName;
        if (filter.userIsSignedUp !== null || filter.userStatus !== null){
          fileName = filter.userStatus !== null ? 
                    (filter.userStatus === "1" ? "Active_users" : filter.userStatus === "0" ? "Inactive_users": "All_users"): "";
          fileName += filter.userIsSignedUp !== null ? 
                    (filter.userIsSignedUp ? "Registered_users": "Non-registered_users") : ""
        }else{
          fileName = (filter.userIsSignedUp === null && filter.userStatus === null) && "All_users"
        }

        const exportedUserFileName = `Encore_Healthcare_${fileName}_${formatted_date}`;
        const sampleFileData = res.data; // This should contain your sample file data
        // Create a Blob object from the file data
        const blob = new Blob([sampleFileData], { type: "text/csv;charset=utf-8" });
        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
        // Create an anchor element
        const anchor = document.createElement("a");
        anchor.download = exportedUserFileName;
        anchor.href = blobUrl;
        // Programmatically click the anchor to trigger the download
        anchor.click();
        // Clean up the URL created for the Blob
        URL.revokeObjectURL(blobUrl);
      }
    }).catch((err) => {
      toast.error(err.response?.data?.message);
      setShowLoader(false)
    });
  }
  return (<>
    <main className="page">
      <div className="title-with-btn">
        <h3 className="mb-0 d-flex align-center text-primary">Employee Summary</h3>
          <div>
              <button className="btn btn-primary btn-long-title" onClick={ShowPopupHandler}>Upload Employee File</button>
          </div>
      </div>
      <div className="emp-card-wrapper">
        <div className="emp-card-container">
          <div className="emp-card">
            <p className="card-title">Total Employees</p>
            <div>
              <div className="emp-card-details">
                <h2 className="emp-count">{summaryData?.totalEmployeesCount}</h2>
                <div className="wrapper-icon">
                  <i className="icon-Total-Employees"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="emp-card-container">
          <div className="emp-card">
            <p className="card-title">Total Registered Employees</p>
            <div>
              <div className="emp-card-details">
                <h2 className="emp-count">{summaryData?.totalRegisteredEmployees}</h2>
                <div className="wrapper-icon">
                  <i className="icon-registered"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="emp-card-container">
          <div className="emp-card">
            <p className="card-title">Total Non-Registered Employees</p>
            <div>
              <div className="emp-card-details">
                <h2 className="emp-count">{summaryData?.totalNonRegisteredEmployees}</h2>
                <div className="wrapper-icon">
                  <i className="icon-registered"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="emp-card-container">
          <div className="emp-card">
            <p className="card-title">Total Inactive Users</p>
            <div>
              <div className="emp-card-details">
                <h2 className="emp-count">{summaryData?.totalInactiveEmployees}</h2>
                <div className="wrapper-icon">
                  <i className="icon-union"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
      <div className="page-header">
        <div className="page-header-left">
          <h3 className="mb-0 d-flex align-center text-primary">Employees Records</h3>
        </div>
        <div className="form-filter-group gap-2">
          <div className="search">
            <input
              name="search-employees"
              className="form-control right-icon"
              type="text"
              placeholder="Search Username"
              onChange={handleSearchChange}
            />
            <i
              className="icon-search"
              onClick={handleSearchChange}
            ></i>
          </div>
          <div className="status">
            <Select
              className="dropdown"
              options={options}
              placeholder="Select Status"
              onChange={(e) => {
                setFilter({
                  ...filter, page: 1, 
                  userStatus: e.value === "0" || e.value === "1" ? e.value : null,
                  userIsSignedUp:
                    e.value === "2" ? true : e.value === "3" ? false : null

                  });
              }}
            />
          </div>
          <button className="btn btn-primary btn-long-title" onClick={exportUserClickHandler}>Export Users</button>
        </div>
      </div>
      <div className="row align-start">
        <div className="content full">
          <Loader show={showLoader} />
          <div className="table-wrapper">
            <table className="employee-table table table-hover">
              <thead>
                <tr>
                  <th className="bg-primary text-white">User Name</th>
                  <th className="bg-primary text-white">Email</th>
                  <th className="bg-primary text-white">Enrollment Date</th>
                  <th className="bg-primary text-white">User SSN</th>
                  <th className="bg-primary text-white">Current Status</th>
                  <th className="bg-primary text-white">Dental Plan</th>
                  <th className="bg-primary text-white">Vision Plan</th>
                  <th className="bg-primary text-white">Medical Plan</th>
                  <th className="bg-primary text-white">Registeration Status</th>
                  <th className="bg-primary text-white">Action</th>
                </tr>
              </thead>
              <tbody>

                {employees.slice(0, 50).map((data) => {
                 
                  return (
                    <tr key={data.id}>
                      <td>{`${data.userFirstName} ${data.userLastName}`}</td>
                      <td>{data.userEmail}</td>
                      <td>{data?.enrollmentDate}</td>
                      <td>{data.userSSN}</td>
                      <td
                        className={`${data.userStatus ? "active" : "in-active"}`}
                      >
                        <span className={`${data.userStatus ? "is-active-true" : "is-active-false"}`}>
                          {data.userStatus ? "Active" : "In Active"}
                        </span>
                      </td>
                      <td className="checked">{data.has_dental_plan ? <input type="checkbox" checked /> : " - "}</td>
                      <td className="checked">{data.has_vision_plan ? <input type="checkbox" checked /> : " - "}</td>
                      <td className="checked">{data.has_medical_plan ? <input type="checkbox" checked /> : " - "}</td>
                      <td className="text-center">
                        {data.userIsSignedUp === true ? "Registered" : "-"}
                      </td>
                      <td
                        onClick={() =>
                          Navigate(`/employees/employees-summarry/${data.id}`)
                        }
                      >
                        <i className="icon-eye"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="total-count">Total Records : {totalCounts}</div>
          <div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={""}
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={paginationLength}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageLinkClassName="page-num"
              previousLinkClassName={`page-num ${
                filter.page === 1 ? "disabled" : ""
              }`}
              nextLinkClassName={`page-num ${
                filter.page === Math.ceil(employeeCount / itemsPerPage) ? "disabled" : ""
              }`}
            />
          </div>
          {employeeCount === 0 && <NoData />}
        </div>
      </div>
      {/* </div> */}
    </main>

    <PopupImportFile
      showFileInput={showFileInput}
      showPopup={showPopup}
      closePopUpHandler={closePopUpHandler}
      handleFileChange={handleFileChange}
      handleFileUpload={handleFileUpload}
      selectedFile={selectedFile}
      setShowFileInput={setShowFileInput}
      DownloadFileHandler={DownloadFileHandler}
      showLoader={showLoader}
    />
    </>
  );
};

export default Employees;
