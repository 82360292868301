import "../assets/SCSS/pages/homescreen-carousel.scss";
// import "../assets/SCSS/pages/employees.scss";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import {
  addNewHomeScreenCard,
  deleteHomeScreenCard,
  editHomeScreenCard,
  getHomeScreenCards,
  updateCardSequence,
  getResourceDocumentsList,
  listOfAdditionalFacility,
} from "../axios/apicall";
import AddNewCardModal from "../components/AddNewHomescreenCardModal";
import { toast } from "react-toastify";
import EditHomescreenCardModal from "../components/EditHomescreenCardModal";
import Swal from "sweetalert2";
import Select from "react-select";

export const HomeScreenCarousel = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [homeScreenCards, setHomeScreenCards] = useState([]);
  const [isAddHomescreenCardModalOpen, setIsAddHomescreenModalOpen] =
    useState(false); // State to control modal visibility
  const [isEditHomescreenCardModalOpen, setIsEditHomescreenCardModalOpen] =
    useState(false); // State to control modal visibility
  const [currentCardData, setCurrentCardData] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);
  const [filter, setFilter] = useState({});
  const [internalRedirectRecords, setInternalRedirectRecords] = useState([]);

  const fetchHomeScreenCards = async () => {
    setShowLoader(true);
    try {
      const response = await getHomeScreenCards({ ...filter });
      if (response.data.status === "success") {
        setHomeScreenCards(response.data.data.homeScreenCards);
      } else {
        toast.error("Failed to fetch home screen cards");
      }
    } catch (error) {
      toast.error("Error fetching home screen cards");
    } finally {
      setShowLoader(false);
    }
  };

  const deleteHomescreenCard = async (id) => {
    setShowLoader(true);
    try {
      const response = await deleteHomeScreenCard(id);
      if (response.data.status === "success") {
        toast.success("Deleted home screen card successfully");
        const homescreenCardsUpdated = homeScreenCards.filter(
          (record) => record.id !== id
        );
        setHomeScreenCards(homescreenCardsUpdated);
      } else {
        toast.error(
          `Failed to delete home screen card, error: ${response.data.message}`
        );
      }
    } catch (error) {
      toast.error("Error deleting home screen card");
    } finally {
      setShowLoader(false);
    }
  };

  const addNewCard = async (newCardData) => {
    setShowLoader(true);
    const { imageFile, imageName, redirect_id } = newCardData; // Destructure to get image file data

    const formData = new FormData();
    formData.append("title", newCardData.title);
    formData.append("link_type", newCardData.link_type);
    formData.append("redirect", newCardData.redirect);
    formData.append("visibleTo", newCardData.visibleTo);
    formData.append("isActive", newCardData.isActive);
    if (redirect_id) formData.append("redirect_id", redirect_id);

    if (imageFile) {
      // Append the raw image file to FormData
      formData.append("image", imageFile, imageName);
    }
    try {
      const response = await addNewHomeScreenCard(formData);
      if (response.data.status === "success") {
        toast.success("Added new home screen card successfully");
        fetchHomeScreenCards(); // Refresh the cards list after adding
      } else {
        toast.error(
          `Failed to add home screen card, error: ${response.data.message}`
        );
      }
    } catch (error) {
      toast.error(
        "Error adding home screen card, error: ",
        response.data.message
      );
    } finally {
      setShowLoader(false);
    }
  };

  const editCard = async (editCardData) => {
    setShowLoader(true);
    const {
      id,
      title,
      link_type,
      redirect,
      visibleTo,
      imageFile,
      imageName,
      isActive,
      redirect_id,
    } = editCardData;

    const formData = new FormData();
    formData.append("id", id);
    if (title) formData.append("title", title);
    if (link_type) formData.append("link_type", link_type);
    if (redirect) formData.append("redirect", redirect);
    if (visibleTo) formData.append("visibleTo", visibleTo);
    if (redirect_id) formData.append("redirect_id", redirect_id);
    if (isActive !== undefined) formData.append("isActive", isActive);

    if (imageFile) {
      // Append the raw image file to FormData
      formData.append("image", imageFile, imageName);
    }
    try {
      const response = await editHomeScreenCard(formData);
      if (response.data.status === "success") {
        toast.success("Updated home screen card successfully");
        fetchHomeScreenCards(); // Refresh the cards list after adding
      } else {
        toast.error(
          `Failed to update home screen card, error: ${response.data.message}`
        );
      }
    } catch (error) {
      toast.error(
        "Error updating home screen card, error: ",
        response.data.message
      );
    } finally {
      setShowLoader(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    fetchHomeScreenCards();
  }, [filter]);

  const deleteHomescreenCardHandler = (id) => {
    // Show SweetAlert confirmation popup
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHomescreenCard(id);
      }
    });
  };

  const fetchRedirectRecords = async (redirect) => {
    if (redirect === "CARE_PROGRAMS") {
      setShowLoader(true);
      try {
        const response = await listOfAdditionalFacility();
        setShowLoader(false);
        if (response.data.status === "success") {
          const records = response.data.data;
          return records;
        }
      } catch (error) {
        setShowLoader(false);
        toast.error("Error fetching CARE PROGRAMS data");
      }
    } else if (redirect === "EDUCATION") {
      setShowLoader(true);
      try {
        const response = await getResourceDocumentsList({});
        setShowLoader(false);
        if (response.data.status === "success") {
          const records = response.data.data;
          return records;
        }
      } catch (error) {
        setShowLoader(false);
        toast.error("Error fetching EDUCATION data");
      }
    }
  };

  const editHomescreenCardHandler = async (data) => {
    if (data.redirect === "EDUCATION" || data.redirect === "CARE_PROGRAMS") {
      const response = await fetchRedirectRecords(data.redirect);
      setInternalRedirectRecords(response);
    }
    setIsEditHomescreenCardModalOpen(true);
    setCurrentCardData(data);
  };

  const userTypeOptions = [
    { value: null, label: "All" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
  ];

  const statusOptions = [
    { value: null, label: "All" },
    { value: true, label: "Active" },
    { value: false, label: "In-Active" },
  ];

  document.title = "Home Screen Carousel";

  // Drag and Drop handlers
  const handleDragStart = (e, record) => {
    setDraggedItem(record);
    e.currentTarget.style.opacity = "0.4";
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
    e.currentTarget.classList.remove("over");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };

  const handleDragEnter = (e) => {
    e.currentTarget.classList.add("over");
  };

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove("over");
  };

  const handleDrop = async (e, targetRecord) => {
    e.preventDefault();
    e.currentTarget.classList.remove("over");

    if (!draggedItem || draggedItem.id === targetRecord.id) return;

    const dragIndex = homeScreenCards.findIndex(
      (item) => item.id === draggedItem.id
    );
    const dropIndex = homeScreenCards.findIndex(
      (item) => item.id === targetRecord.id
    );

    // Create new array with reordered items
    const newCards = [...homeScreenCards];
    newCards.splice(dragIndex, 1);
    newCards.splice(dropIndex, 0, draggedItem);

    // Update sequence numbers
    const updatedCards = newCards.map((card, index) => ({
      ...card,
      sequence: index + 1,
    }));

    setHomeScreenCards(updatedCards);

    // Send sequence update to backend
    try {
      setShowLoader(true);
      const sequenceData = updatedCards.map((card) => ({
        id: card.id,
        seqNo: card.sequence,
      }));

      const response = await updateCardSequence({ sequenceData }, "homescreen");

      if (response.data.status === "success") {
        toast.success("Card sequence updated successfully");
      } else {
        toast.error("Failed to update card sequence");
        fetchHomeScreenCards();
      }
    } catch (error) {
      toast.error("Error updating card sequence");
      fetchHomeScreenCards();
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <main className="page">
        <div className="flex justify-between mb-3">
          <h3 className="mb-0 text-primary">Home Screen Carousel</h3>
          <div className="flex justify-between">
            <div className="user-type">
              <Select
                className="dropdown"
                options={userTypeOptions}
                placeholder="Select User Type"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    visibleTo:
                      e.value === "Enrolled" || e.value === "Pre-Enrolled"
                        ? e.value
                        : null,
                  });
                }}
              />
            </div>
            <div className="status">
              <Select
                className="dropdown"
                options={statusOptions}
                placeholder="Select Status"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    isActive:
                      e.value === true || e.value === false ? e.value : null,
                  });
                }}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => setIsAddHomescreenModalOpen(true)} // Open modal on click
            >
              Add +
            </button>
          </div>
        </div>
        <div className="row align-start">
          <div className="content full">
            <Loader show={showLoader} />
            <div className="table-wrapper">
              <table className="homescreen-table table table-hover">
                <thead>
                  <tr>
                    <th className="bg-primary text-white">Title</th>
                    <th className="bg-primary text-white">Icon</th>
                    <th className="bg-primary text-white">Redirection Type</th>
                    <th className="bg-primary text-white">Redirect To</th>
                    <th className="bg-primary text-white">Visible To</th>
                    <th className="bg-primary text-white">Status</th>
                    <th className="bg-primary text-white">Created At</th>
                    <th className="bg-primary text-white">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {homeScreenCards.map((record) => (
                    <tr
                      key={record.id}
                      draggable={true}
                      onDragStart={(e) => handleDragStart(e, record)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, record)}
                      className="cursor-move"
                    >
                      <td>{record.title}</td>
                      <td>
                        {record.image ? (
                          <a
                            href={record.image}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="decoration-none"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{record.link_type}</td>
                      <td>
                        {record.link_type === "external" ? (
                          <a
                            href={record.redirect}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="decoration-none"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        ) : (
                          record.redirect
                        )}
                      </td>
                      <td>{record.visibleTo}</td>
                      <td>
                        <div
                          className={
                            record.isActive
                              ? "is-active-true"
                              : "is-active-false"
                          }
                        >
                          {record.isActive ? "Active" : "Inactive"}
                        </div>
                      </td>
                      <td>{formatDate(record.updatedAt)}</td>
                      <td>
                        <div className="flex justify-center">
                          <button
                            className="btn-icon"
                            onClick={() => editHomescreenCardHandler(record)} // Open modal on click
                          >
                            <i className="icon-edit"></i>
                          </button>
                          <button
                            className="btn-icon"
                            onClick={() =>
                              deleteHomescreenCardHandler(record.id)
                            }
                          >
                            <i className="icon-delete"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      {/* Add New Card Modal */}
      {isAddHomescreenCardModalOpen && (
        <AddNewCardModal
          onClose={() => setIsAddHomescreenModalOpen(false)} // Close modal handler
          onSubmit={addNewCard} // Submit handler
        />
      )}

      {isEditHomescreenCardModalOpen && (
        <EditHomescreenCardModal
          onClose={() => setIsEditHomescreenCardModalOpen(false)} // Close modal handler
          onSubmit={editCard} // Submit handler
          currentCardData={currentCardData}
          internalRedirectRecords={internalRedirectRecords}
        />
      )}
    </>
  );
};
