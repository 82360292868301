import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/SCSS/components/_sidebar.scss";

const Sidebar = () => {
	const location = useLocation();
	const menuLinks = [
		{
			id: 1,
			icon: "icon-Total-Employees",
			title: "Dashboard",
			link: "/employees",
			hasRightArrow: true,
		},
		{
			id: 2,
			icon: "icon-registered",
			title: "Manage Education Documents",
			link: "/resource-documents",
			hasRightArrow: true,
		},
		{
			id: 3,
			icon: "icon-id-card",
			title: "Manage Carousel Items",
			link: "/homescreen-carousel",
			hasRightArrow: true,
		},
		{
			id: 4,
			icon: "icon-planning",
			title: "Manage Alerts",
			link: "/dynamic-alert",
			hasRightArrow: true,
		},
		{
			id: 5,
			icon: "icon-upload-card",
			title: "Push Notifications",
			link: "/notifications",
			hasRightArrow: true,
		},
		{
			id: 6,
			icon: "icon-exit",
			title: "Logout",
			link: "/login",
			hasRightArrow: false,
		},
	];

	return (
		<aside className="sidebar site-sidebar">
			<ul className="sidebar-menu">
				{menuLinks.map((menu) => (
					<li key={menu.id} className="menu-item">
						<Link to={menu.link} className={location.pathname === menu.link ? "active" : ""}>
							<i className={`${menu.icon} menu-icon`}></i>
							{menu.title}
							{menu.hasRightArrow && <i className="icon-chevron-right-solid right-arrow"></i>}
						</Link>
					</li>
				))}
			</ul>
		</aside>
	);
};

export default Sidebar;
