import React, { useState, useEffect, useCallback, useRef } from "react";
import "../assets/SCSS/pages/notification.scss";
import Select from "react-select";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import {
  sendNotifications,
  getNotifications,
  getResourceDocumentsList,
  listOfAdditionalFacility,
  getUsersWithDeviceToken,
  deleteAdminNotificationById,
} from "../axios/apicall"; // Import the necessary API functions
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import NoData from "../components/NoData";
import ReactPaginate from "react-paginate";

const NotificationForm = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null); // State for the selected image file
  const [imageUrl, setImageUrl] = useState(""); // State for the uploaded image URL
  const [imageName, setImageName] = useState("Click or Drag to Upload Image"); // State for the uploaded image Name
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState("");
  const imgRef = useRef(null);
  const [isCropping, setIsCropping] = useState(false); // New state variable
  const [topic, setTopic] = useState(null);
  const [type, setType] = useState(null);
  const [redirectKeyword, setRedirectKeyword] = useState(null);
  const [url, setUrl] = useState("");
  const [redirectId, setRedirectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [redirectOptions, setRedirectOptions] = useState([]); // New state for redirect options
  const [showPopup, setShowPopup] = useState(false);
  const [countdown, setCountdown] = useState(5); // New state for countdown
  const [stopSendNotification, setStopSendNotification] = useState(false);
  const [timer, setTimer] = useState(null); // Track the timer ID
  const [searchString, setSearchString] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(""); // State for selected user
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  const itemsPerPage = 15; // Set the number of items per page

  const topics = [
    { value: "individual", label: "Individual" },
    { value: "all_users", label: "All Users" },
    { value: "all_employees", label: "All Employees" },
    { value: "all_spouse_and_children", label: "All Spouse and Children" },
    { value: "plan_a_users", label: "Plan A Users" },
    { value: "plan_b_users", label: "Plan B Users" },
  ];

  const internalRedirectOptions = [
    { value: "HOME", label: "HOME" },
    { value: "CARE_PROGRAMS", label: "CARE PROGRAMS" },
    { value: "ID_CARD", label: "ID CARD" },
    { value: "EDUCATION", label: "EDUCATION" },
    { value: "IN_NETWORK_DOCTORS", label: "IN NETWORK DOCTORS" },
    { value: "PLAN_DETAILS", label: "PLAN DETAILS" },
    { value: "DEPENDENT", label: "DEPENDENT" },
    { value: "MESSAGE_FROM_CHAIRMAN", label: "MESSAGE FROM CHAIRMAN" },
    { value: "MY_PROFILE", label: "MY PROFILE" },
    { value: "GENERAL", label: "GENERAL" },
  ];

  const externalRedirectOptions = [
    { value: "PDF", label: "PDF" },
    { value: "VIDEO", label: "VIDEO" },
    { value: "URL", label: "URL" },
  ];

  const fetchNotifications = async (page = 1) => {
    setLoading(true);
    try {
      const response = await getNotifications({ page, limit: itemsPerPage });
      if (response.data.status === "success") {
        setNotifications(response.data.data.notifications);
        setPageCount(Math.ceil(response.data.data.count / itemsPerPage));
        setCurrentPage(page);
      } else {
        toast.error("Failed to fetch notifications");
      }
    } catch (error) {
      toast.error("Error fetching notifications");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  const handleTypeChange = (selectedOption) => {
    setType(selectedOption);
    setRedirectKeyword(null);
    setUrl("");
    setRedirectId(null);
    setRedirectOptions([]);
  };

  const handleRedirectKeywordChange = async (selectedOption) => {
    setRedirectKeyword(selectedOption);
    setRedirectId(null);
    setRedirectOptions([]);

    if (selectedOption.value === "CARE_PROGRAMS") {
      setLoading(true);
      try {
        const response = await listOfAdditionalFacility();
        setLoading(false);
        if (response.data.status === "success") {
          setRedirectOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.title,
            }))
          );
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching CARE PROGRAMS data");
      }
    } else if (selectedOption.value === "EDUCATION") {
      setLoading(true);
      try {
        const response = await getResourceDocumentsList({});
        setLoading(false);
        if (response.data.status === "success") {
          setRedirectOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.fileName,
            }))
          );
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching EDUCATION data");
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageName(file.name);

      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImageName("Click or Drag to Upload Image");
      setImageUrl("");
      setCroppedImage(null);
      setCompletedCrop(null);
    }
  };

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const crop = completedCrop;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Use the crop dimensions for the canvas size
    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const baseFile = canvas.toDataURL("image/png");
    setCroppedImage(baseFile);
  }, [completedCrop]);

  const onLoad = useCallback((e) => {
    const { width, height } = e.currentTarget;
    const cropSize = Math.min(width, height);
    const x = (width - cropSize) / 2;
    const y = (height - cropSize) / 2;
    const crop = {
      unit: "px",
      x,
      y,
      width: cropSize,
      height: cropSize,
    };
    setCrop(crop);
  }, []);

  const handleCropStart = useCallback(() => {
    setIsCropping(true);
  }, []);

  const onCompleteCrop = useCallback((c) => {
    if (!c || c?.width === 0 || c?.height === 0) {
      return;
    }
    setCompletedCrop(c);
  }, []);

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setImageFile(null);
    setImageUrl("");
    setImageName("Click or Drag to Upload Image");
    setCrop();
    setCroppedImage(null);
    setCompletedCrop(null);
    setTopic(null);
    setType(null);
    setRedirectKeyword(null);
    setUrl("");
    setRedirectId(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await handleShowPopup(); // Show the popup

    if (stopSendNotification) {
      // If cancellation flag is set, reset it and prevent submission
      setStopSendNotification(false);
      return;
    }

    // Form validation for external type with URL redirect
    if (type.value === "external" && !url) {
      toast.error("URL is required for external type with URL redirect");
      return;
    }

    if (topic?.value === "individual" && !selectedUser) {
      toast.error("Please select a user for individual notifications");
      return;
    }

    const formData = new FormData();
    const userTypeValue = topic.value !== "individual" ? "group": "individual";
    const topicValue = topic.value !== "individual" ? topic.value : "all_users";
    // Append text fields to the formData object
    formData.append("title", title);
    formData.append("description", description);
    formData.append("topic", topicValue);
    formData.append("type", type.value);
    formData.append(
      "redirect_keyword",
      type.value === "external" ? "URL" : redirectKeyword.value
    );
    formData.append("redirect_link", type.value === "external" ? url : "");
    formData.append("user_type", userTypeValue);
    if (topic.value === "individual") {
      console.log({selectedUser: selectedUser.map(user => user.value).join(",")});
      const selectedUserIds = selectedUser.map(user => user.value).join(",");
      formData.append("user_ids", selectedUserIds);
    }

    if (imageFile) {
      if (croppedImage) {
        const base64 = await (await fetch(croppedImage)).blob();
        formData.append("image", base64, imageName);
      } else {
        toast.error("Image is not properly cropped");
      }
    }

    // Append redirect_id if applicable
    if (
      type.value === "internal" &&
      (redirectKeyword.value === "CARE_PROGRAMS" ||
        redirectKeyword.value === "EDUCATION")
    ) {
      formData.append("redirect_id", String(redirectId.value));
    }

    setLoading(true);

    try {
      const response = await sendNotifications(formData);
      setLoading(false);

      if (response.data.status === "success") {
        toast.success("Notification sent successfully");
        fetchNotifications(currentPage); // Refresh the notifications list
        // Reset all fields
        resetForm();
      } else {
        toast.error(
          `Failed to send notification, message: ${response.data.message}`
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Error sending notification, message: ${error.message}`);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    let timer;

    if (showPopup && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (showPopup && countdown === 0) {
      handleClosePopup(); // Close popup when countdown reaches 0
    }

    // Clean up the timer on component unmount or when `showPopup` or `countdown` changes
    return () => clearInterval(timer);
  }, [showPopup, countdown]);

  const handleShowPopup = () => {
    if (timer) clearInterval(timer);

    setStopSendNotification(false);
    setShowPopup(true);
    setCountdown(5);
    return new Promise((resolve) => {
      setShowPopup(true);
      setCountdown(5); // Reset the countdown to 5 when showing the popup

      // Resolve the promise when countdown reaches 0
      const timerId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timerId);
            setShowPopup(false);
            resolve(); // Resolve the promise when countdown reaches 0
          }
          return prevCountdown - 1;
        });
      }, 1000);

      setTimer(timerId); // Store the timer ID
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setCountdown(5); // Reset the countdown to 5 when closing the popup
  };

  const handleCancel = () => {
    resetForm();
    if (timer) {
      clearInterval(timer); // Clear the timer
      setTimer(null);
    }
    setStopSendNotification(true);
    setShowPopup(false);
  };

  const handleTopicChange = async (selectedOption) => {
    setTopic(selectedOption);
    setSelectedUser(null);
    if (selectedOption.value === "individual") {
      setLoading(true);
      try {
        const response = await getUsersWithDeviceToken();
        if (response.data.status === "success") {
          setUsers(
            response.data.data.users.map((user) => ({
              value: user.id,
              label: `${user.userFirstName} ${user.userLastName}`,
            }))
          );
        } else {
          toast.error("Failed to fetch users");
        }
      } catch (error) {
        toast.error("Error fetching users");
      } finally {
        setLoading(false);
      }
    }
  };


  useEffect(()=>{
    if (deleteNotificationId)
      handleDeleteNotification();
  },[deleteNotificationId]);

  const handleDeleteNotification = async () => {
    setLoading(true);
    try {
      const response = await deleteAdminNotificationById(deleteNotificationId);
      if (response.data.status === "success") {
        fetchNotifications(currentPage);
      } else {
        toast.error("Failed to delete notification");
      }
    } catch (error) {
      toast.error(`Error deleting notification, error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  document.title = "Notifications";

  return (
    <div className="page">
    <div className="notification-panel">
      <div className="send-notification-form">
        <h3 className="mb-3 d-flex align-center text-primary employee-summary-heading">
          Send Notification
        </h3>
        <form className="notification-form" onSubmit={handleSubmit}>
          <div className="notification-form-left">
            <div>
              <input
                className="form-control"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                required
              />
            </div>
            <div>
              <textarea
                className="form-control text-area"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                required
              />
            </div>
            <div>
              <Select
                className="dropdown"
                options={[
                  { value: "internal", label: "Internal" },
                  { value: "external", label: "External" },
                ]}
                value={type}
                onChange={handleTypeChange}
                placeholder="Select type"
                required
              />
            </div>
            {type && type.value === "internal" && (
              <div>
                <Select
                  className="dropdown"
                  options={internalRedirectOptions}
                  value={redirectKeyword}
                  onChange={handleRedirectKeywordChange}
                  placeholder="Select redirect keyword"
                  required
                />
              </div>
            )}
            {type && type.value === "external" && (
              <div>
                <input
                  className="form-control url-input"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter URL"
                  required
                />
              </div>
            )}
            {redirectKeyword &&
              (redirectKeyword.value === "CARE_PROGRAMS" ||
                redirectKeyword.value === "EDUCATION") && (
                <div>
                  <Select
                    className="dropdown"
                    options={redirectOptions}
                    value={redirectId}
                    onChange={setRedirectId}
                    placeholder="Select redirect option"
                    required
                  />
                </div>
              )}
            <div>
              <Select
                className="dropdown"
                options={topics}
                value={topic}
                onChange={handleTopicChange}
                placeholder="Select Users"
                required
              />
            </div>
            {topic && topic.value === "individual" && (
              <div className="form-group">
                {/* <label htmlFor="user">Select User:</label> */}
                <Select
                  className="dropdown"
                  options={users}
                  value={selectedUser}
                  onChange={(selectedOption) => setSelectedUser(selectedOption)}
                  placeholder="Select User"
                  isClearable
                  isMulti
                  styles={{
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: '#0064A9',
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: '#fff',
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      color: '#fff',
                    }),
                  }}
                />
              </div>
            )}
            <div>
              <button type="submit" className="btn btn-primary">
                Send Notification
              </button>
            </div>
          </div>
          <div className="notification-form-right">
            <div className="image-input-wrapper">
              <input
                className="form-control image-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              <span className="image-name">{imageName}</span>
              {imageUrl && (
                <div className="crop-preview">
                  <ReactCrop
                    crop={crop}
                    onChange={(newCrop, percentCrop) => {
                      // Ensure the crop remains square
                      const size = Math.min(newCrop.width, newCrop.height);
                      setCrop({ ...newCrop, width: size, height: size });
                    }}
                    onComplete={onCompleteCrop}
                    onDragStart={handleCropStart}
                    aspect={1}
                  >
                    <img
                      className="crop-image"
                      ref={imgRef}
                      src={imageUrl}
                      alt=""
                      onLoad={onLoad}
                      id="Img"
                    />
                  </ReactCrop>
                </div>
              )}
            </div>
            {/* {isCropping && (
							<div className="preview">
								<img src={croppedImage} alt="cropped" title="cropped" />
							</div>
						)} */}
          </div>
        </form>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Sending notification in {countdown} </p>
            <button onClick={handleCancel} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="notification-list">
        <h3 className="mb-3 d-flex align-center text-primary employee-summary-heading">
          Notifications
        </h3>
        <div className="row align-start">
          <div className="content full">
            <Loader show={loading} />
            <div className="table-wrapper">
              <table className="notifications-table table table-hover">
                <thead>
                  <tr>
                    <th className="bg-primary text-white">Title</th>
                    <th className="bg-primary text-white">Description</th>
                    <th className="bg-primary text-white">Image</th>
                    <th className="bg-primary text-white">User Type</th>
                    <th className="bg-primary text-white">Type</th>
                    <th className="bg-primary text-white">Redirect</th>
                    <th className="bg-primary text-white">Redirect Link</th>
                    <th className="bg-primary text-white">Redirect ID</th>
                    <th className="bg-primary text-white">Created At</th>
                    <th className="bg-primary text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications.map((data, index) => (
                    <tr key={index}>
                      <td>{data.title || "-"}</td>
                      <td>{data.description || "-"}</td>
                      {/* <td>{data.image || "-"}</td> */}
                      <td>
                        {data.image ? (
                          <a
                            href={data.image}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{`${data.topic || "-"}`}</td>
                      <td>{data.type || "-"}</td>
                      <td>{data.redirect_keyword || "-"}</td>
                      <td>
                        {data.redirect_link ? (
                          <a
                            href={data.redirect_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{data.redirect_id || "-"}</td>
                      <td>
                        {data.createdAt ? formatDate(data.createdAt) : "-"}
                      </td>
                      <td>
                          <a
                            className="btn-icon decoration-none"
                            href="#"
                            onClick={() => setDeleteNotificationId(data.id)}
                          ><i className="icon-delete"></i></a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={""}
                pageCount={pageCount}
                marginPagesDisplayed={0}
                pageRangeDisplayed={itemsPerPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName="page-num"
                previousLinkClassName={`page-num ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                nextLinkClassName={`page-num ${
                  currentPage === pageCount ? "disabled" : ""
                }`}
              />
            </div>
            {notifications.length === 0 && <NoData />}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default NotificationForm;
