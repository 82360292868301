import { useState } from "react";
import Select from "react-select";

const AddNewAlertModal = ({ onClose, onSubmit }) => {
  const [newAlert, setNewAlert] = useState({
    description: "",
    visibleTo: "All",
    isActive: true,
  });

  const handleChange = (e) => {
    setNewAlert({ ...newAlert, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption) => {
    setNewAlert({ ...newAlert, visibleTo: selectedOption.value });
  };

  const handleSubmit = () => {
    onSubmit({ ...newAlert });
    onClose();
  };

  const toggleActive = () => {
    setNewAlert((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  const visibilityOptions = [
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "All", label: "All" },
  ];

  return (
    <div className="popup">
      <div className="content">
        <button onClick={onClose} className="close-btn">
          &times;
        </button>
        <h3 className="popup-header">Add New Alert</h3>

        <div className="change-alert-status mb-2">
          <span>Status:</span>
          <div className="d-flex ml-1">
            <button
              className={`${newAlert.isActive ? "active" : "in-active"} left`}
              onClick={() => toggleActive()} // Set to active on click
            >
              Active
            </button>
            <button
              className={`${!newAlert.isActive ? "active" : "in-active"} right`}
              onClick={() => toggleActive()} // Set to inactive on click
            >
              In Active
            </button>
          </div>
        </div>

        <div className="main-content">
          <div className="d-flex mb-2">
            <textarea
              name="description"
              value={newAlert.description}
              onChange={handleChange}
              placeholder="Description"
              className="form-control"
            />
          </div>
          <div className="d-flex">
            <Select
              className="dropdown"
              options={visibilityOptions}
              value={visibilityOptions.find(
                (option) => option.value === newAlert.visibleTo
              )}
              onChange={handleSelectChange}
              isSearchable={false}
              required
            />
          </div>
        </div>
        <div className="popup-footer">
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewAlertModal;
