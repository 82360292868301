import { useState, useEffect } from "react";
import Select from "react-select";

const EditAlertModal = ({ onClose, onSubmit, currentAlertData }) => {
    const [data, setData] = useState({
        id: "",
        description: "",
        visibleTo: "Pre-Enrolled",
        isActive: false,
      });
    
      useEffect(() => {
        if (currentAlertData) {
          setData({
            id: currentAlertData.id ? String(currentAlertData.id) : "",
            description: currentAlertData.description || "",
            visibleTo: currentAlertData.visibleTo || "All",
            isActive: currentAlertData.isActive ?? false,
          });
        }
      }, [currentAlertData]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
      onSubmit({ ...data });
      onClose();
  };

   const toggleActive = () => {
    setData((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  const visibilityOptions = [
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "All", label: "All" },
  ];

  return (
    <div className="popup">
      <div className="content">
        <button onClick={onClose} className="close-btn">
					&times;
				</button>
        <h3 className="popup-header">
        Edit Item
        </h3>
        <div className="main-content">

        <div className="change-alert-status mb-2">
          <span>Status:</span>
          <div className="d-flex ml-1">
            <button
              className={`${
                data.isActive ? "active" : "in-active"
              } left`}
              onClick={() => toggleActive()} // Set to active on click
            >
              Active
            </button>
            <button
              className={`${
                !data.isActive ? "active" : "in-active"
              } right`}
              onClick={() => toggleActive()} // Set to inactive on click
            >
              In Active
            </button>
          </div>
        </div>

        <input
          type="text"
          name="description"
          value={data.description}
          onChange={handleChange}
          placeholder="Description"
          className="form-control mb-2"
        />
        <Select
          name="visibleTo"
          value={visibilityOptions.find((option) => option.value === data.visibleTo)}
          onChange={(selectedOption) => handleChange({ target: { name: 'visibleTo', value: selectedOption.value } })}
          options={visibilityOptions}
          isSearchable={false}
          placeholder="Select visibility"
        />
        </div>
        <div className="popup-footer">
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAlertModal;
