import { useRef, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

const AddNewResourceDocumentModal = ({ onClose, onSubmit }) => {
  const [newCard, setNewCard] = useState({
    fileName: "",
    fileType: "",
    visibleTo: "All",
    isActive: true,
  });

  const [file, setFile] = useState(null);
  const [uploadFileName, setUploadFileName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [uploadImageFileName, setUploadImageFileName] = useState("");

  const fileInputRef = useRef(null); // For file (PDF or video)
  const thumbnailInputRef = useRef(null); // For thumbnail image

  // Handle changes to text fields
  const handleChange = (e) => {
    setNewCard({ ...newCard, [e.target.name]: e.target.value });
  };

  // Handle changes to select fields
  const handleSelectChange = (field, selectedOption) => {
    setNewCard({ ...newCard, [field]: selectedOption.value });
  };

  // Handle file submission
  const handleSubmit = () => {
    if (!newCard.fileName || !file || !imageFile) {
      toast.error("Please fill out all required fields");
      return;
    }

    onSubmit({
      ...newCard,
      file,
      uploadFileName,
      imageFile,
      uploadImageFileName,
    });
    onClose();
  };

  // Handle changes to the file (PDF or video)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setUploadFileName(file.name);
    }
  };

  // Handle changes to the thumbnail image
  const handleThumbnailChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      setImageFile(image);
      setUploadImageFileName(image.name);
    }
  };

  // Handle file button click (PDF or video)
  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input
      fileInputRef.current.click(); // Trigger the file dialog
    }
  };

  // Handle thumbnail button click (image)
  const handleThumbnailButtonClick = () => {
    if (thumbnailInputRef.current) {
      thumbnailInputRef.current.value = ""; // Clear the input
      thumbnailInputRef.current.click(); // Trigger the thumbnail dialog
    }
  };

  const toggleActive = () => {
    setNewCard((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  const fileTypeOptions = [
    { value: "pdf", label: "PDF" },
    { value: "video", label: "VIDEO" },
  ];

  const visibilityOptions = [
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "All", label: "All" },
  ];

  return (
    <div className="popup">
      <div className="content">
        <button onClick={onClose} className="close-btn">
          &times;
        </button>
        <h3 className="popup-header">Add Resource Document</h3>
        <div className="change-alert-status mb-2">
          <span>Status:</span>
          <div className="d-flex ml-1">
            <button
              className={`${newCard.isActive ? "active" : "in-active"} left`}
              onClick={() => toggleActive()} // Set to active on click
            >
              Active
            </button>
            <button
              className={`${!newCard.isActive ? "active" : "in-active"} right`}
              onClick={() => toggleActive()} // Set to inactive on click
            >
              In Active
            </button>
          </div>
        </div>
        <div className="main-content">
          <Select
            name="fileType"
            options={fileTypeOptions}
            value={fileTypeOptions.find(
              (option) => option.value === newCard.fileType
            )}
            onChange={(selectedOption) =>
              handleSelectChange("fileType", selectedOption)
            }
            isSearchable={false}
            placeholder="Select File Type"
            required
            className="dropdown mb-2"
          />
          <input
            type="text"
            className="form-control image-input"
            name="fileName"
            value={newCard.fileName}
            onChange={handleChange}
            placeholder="File Name"
          />

          {/* Thumbnail Upload */}
          <div>
            <button
              className="main-content-file-upload"
              onClick={handleThumbnailButtonClick}
            >
              <span className="file-heading">
                <i className="icon-cloud-upload"></i> Click here Upload
                Thumbnail
              </span>
              {uploadImageFileName && (
                <p className="file-name-text">
                  Selected file: {uploadImageFileName}
                </p>
              )}
            </button>

            <input
              placeholder="File name"
              className="form-control image-input"
              type="file"
              onChange={handleThumbnailChange}
              style={{ display: "none" }}
              accept="image/*"
              ref={thumbnailInputRef}
            />
          </div>
          <Select
            className="dropdown mb-2"
            name="visibleTo"
            options={visibilityOptions}
            value={visibilityOptions.find(
              (option) => option.value === newCard.visibleTo
            )}
            onChange={(selectedOption) =>
              handleSelectChange("visibleTo", selectedOption)
            }
            isSearchable={false}
            required
          />

          {/* File Upload (PDF/Video) */}
          <div>
            <button
              className="main-content-file-upload"
              onClick={handleFileButtonClick}
            >
              <span className="file-heading">
                <i className="icon-cloud-upload"></i> Click here Upload File
              </span>
              {uploadFileName && (
                <p className="file-name-text">
                  Selected file: {uploadFileName}
                </p>
              )}
            </button>

            <input
              placeholder="File name"
              className="form-control image-input"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="application/pdf, video/*"
              ref={fileInputRef}
            />
          </div>
        </div>

        <div className="popup-footer">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewResourceDocumentModal;
