import { useState, useEffect, useRef } from "react";
import Select from 'react-select';

const EditResourceDocumentModal = ({ onClose, onSubmit, currentDocumentData }) => {
  const [data, setData] = useState({
    id: "",
    fileName: "",
    fileType: "pdf",
    visibleTo: "All",
    isActive: false,
  });
  const [file, setFile] = useState(null);
  const [uploadFileName, setUploadFileName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [uploadImageFileName, setUploadImageFileName] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const fileInputRef = useRef(null);
  const thumbnailInputRef = useRef(null); // For thumbnail image


  useEffect(() => {
    if (currentDocumentData) {
      setData({
        id: currentDocumentData.id || "",
        fileName: currentDocumentData.fileName || "",
        fileType: currentDocumentData.fileType || "pdf",
        visibleTo: currentDocumentData.visibleTo || "Pre-Enrolled",
        isActive: currentDocumentData.isActive ?? true,
      });
    }
  }, [currentDocumentData]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (field, selectedOption) => {
    setData({ ...data, [field]: selectedOption.value });
  };

  const handleSubmit = () => {
    onSubmit({ ...data, file, uploadFileName, imageFile, uploadImageFileName });
    onClose();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setUploadFileName(file.name);
    }
  };

  // Handle changes to the thumbnail image
  const handleThumbnailChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      setImageFile(image);
      setUploadImageFileName(image.name);
    }
  };

  const visibilityOptions = [
    { value: 'Pre-Enrolled', label: 'Pre-Enrolled' },
    { value: 'Enrolled', label: 'Enrolled' },
    { value: 'All', label: 'All' },
  ];

  const fileTypeOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'video', label: 'VIDEO' },
  ];

  const handleFileButtonClick = () => {
    setShowFileInput(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleThumbnailButtonClick = () => {
    setShowFileInput(true);
    if (thumbnailInputRef.current) {
      thumbnailInputRef.current.value = '';
      thumbnailInputRef.current.click();
    }
  };

  // Toggle function for Active/Inactive state
  const toggleActive = () => {
    setData((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  return (
    <div className="popup">
      <div className="content">
        <button onClick={onClose} className="close-btn">
            &times;
          </button>
        <h3 className="popup-header">
          Edit Resource Document
        </h3>
        <div className="main-content">
          {/* Updated Active/Inactive toggle */}
          <div className="change-alert-status mb-2">
            <span>Status:</span>
            <div className="d-flex ml-1">
              <button
                className={`left ${data.isActive ? "active" : "in-active"}`}
                onClick={() => toggleActive()}
              >
                Active
              </button>
              <button
                className={`right ${!data.isActive ? "active" : "in-active"}`}
                onClick={() => toggleActive()}
              >
                Inactive
              </button>
            </div>
          </div>
         <Select
          name="fileType"
          options={fileTypeOptions}
          value={fileTypeOptions.find(
            (option) => option.value === data.fileType
          )}
          onChange={(selectedOption) =>
            handleSelectChange('fileType', selectedOption)
          }
          isSearchable={false}
          placeholder="Select File Type"
          required
          className="dropdown mb-2"
        />
        <input
          type="text"
          name="fileName"
          value={data.fileName}
          onChange={handleChange}
          placeholder="Title"
          className="form-control mb-2"
        />
        <div>
          <button className="main-content-file-upload" onClick={handleThumbnailButtonClick}>
            <span className="file-heading">
              <i className="icon-cloud-upload"></i> Click here Upload Thumbnail
            </span>
            {uploadImageFileName && (
              <p className="file-name-text">Selected file: {uploadImageFileName}</p>
            )}
          </button>

          {showFileInput && (
            <input
              placeholder="File name"
              className="form-control image-input"
              type="file"
              onChange={handleThumbnailChange}
              style={{ display: 'none' }}
              ref={thumbnailInputRef}
              accept="image/*"
            />
          )}
        </div>
        <Select
          className="dropdown mb-2"
          options={visibilityOptions}
          value={visibilityOptions.find(
            (option) => option.value === data.visibleTo
          )}
          onChange={(selectedOption) =>
            handleSelectChange('visibleTo', selectedOption)
          }
          isSearchable={false}
          placeholder="Visible To"
          required
        />
        <div>
          <button className="main-content-file-upload" onClick={handleFileButtonClick}>
            <span className="file-heading">
              <i className="icon-cloud-upload"></i> Click here Upload File
            </span>
            {uploadFileName && (
              <p className="file-name-text">Selected file: {uploadFileName}</p>
            )}
          </button>

          {showFileInput && (
            <input
              placeholder="File name"
              className="form-control image-input"
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
              accept="application/pdf, video/*"
            />
          )}
        </div>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditResourceDocumentModal;
