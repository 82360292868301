import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfoAction, logout } from "../features/login/AuthSlice";
import { toast } from "react-toastify";
import { logOut, adminLogout } from "../features/logout/Logout";
import { getUserInfo } from "../axios/apicall";
import CONFIG from "../config/api.config"
import "../assets/SCSS/components/_header.scss";
import planDetailDoc from "../assets/docs/Employee-Plan.csv"
import NotificationIcon from "../assets/images/notification-icon.png";

// Image imports
import logo from "../assets/images/encore.svg";
import profilePic from "../assets/images/user-profile.png";

const Header = (props) => {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const location = useLocation();
	const [showLoader, setShowLoader] = useState(true);
	const [searchString, setSearchString] = useState("");
	const userDetail = useSelector((state) => state.auth);
	const userDataLoaded = userDetail.userFetched;
	const user = userDetail?.user || "";
	const userType = user?.userTiear || "";

	// Notification Navigation
	const Navigate = useNavigate();

	useEffect(() => {
		setShowLoader(false);
	}, []);

	// Load Loged in User Data
	const loadUserData = () => {
		if (localStorage.getItem("encore-auth-token") && !localStorage.getItem("encore-admin")) {
			if (userDataLoaded === false) {
				setShowLoader(true);
				getUserInfo()
					.then((res) => {
						if (res.data.status === "failure") {
							toast.error(res.data.message, { toastId: "getUserInfoError" });
							setShowLoader(false);
							logOut(dispatch(logout()), navigate("/login")); // Logout user if failed to load data
						}
						if (res.data.status === "success") {
							dispatch(getUserInfoAction(res.data));
							setShowLoader(false);
						}
					})
					.catch((err) => {
						toast.error(err.response?.data?.message, { toastId: "getUserInfoCatchError" });
						setShowLoader(false);
						logOut(dispatch(logout()), navigate("/login")); // Logout user if failed to load data
					});
			}
		}
	};

	useEffect(() => {
		loadUserData();
	}, [userDetail]);

	// Header Search Field for Globla search
	const searchData = (e) => {
		setSearchString(e.target.value);
	};

	// Submit Search Data
	const searchSubmit = (e) => {
		e.preventDefault();
		navigate(`/all-providers?search=${searchString}`);
	};
	const showSubmenu = (show) => {
		const menu = document.querySelector('.header-profile')
		if (show == true) {
			menu?.classList.add('active')
		} else {
			menu?.classList.remove('active')
		}
	}
	return (
		<>
			<header className="site-header">
				<div className="container">
					{/* Logo */}
					{localStorage.getItem("encore-admin") ?
						<Link to="/admin/chat">
							<img className="site-logo" src={logo} alt="Encore" />
						</Link> :
						<Link to="/employees">
							<img className="site-logo" src={logo} alt="Encore" />
						</Link>
					}
					{/* Logo End */}

					{/* Global Search Field */}
					{/* {location.pathname == "/all-providers" ? (
						<form onSubmit={searchSubmit}>
							<div className="header-search">
								<div className="form-group">
									<div className="input-group">
										<input name="header-search" className="form-control icon" type="text" placeholder="Search Here..." value={searchString || ""} onChange={searchData} />
										<span className="input-icon">
											<i className="icon-search"></i>
										</span>
									</div>
								</div>
							</div>
						</form>
					) : null} */}

					{/* User Profile */}
					<div className="header-right">
						<div className="header-profile decoration-none" onMouseMove={() => showSubmenu(true)} onMouseLeave={() => showSubmenu(false)}>
							<div className="profile-user-name">
								<span>Hello</span>
								<div className="user-name">
									{
										localStorage.getItem("encore-admin") ? "Admin" : user?.userFullName
									}
								</div>
							</div>
							<img className="profile-user-pic" src={user?.userProfilePic ? user?.userProfilePic : profilePic} alt={user?.userFirstName} />
							{/* User Dropdown */}
							{
								!localStorage.getItem("encore-admin") ? <div className="user-menu">
									<ul>
										<li>
											<Link onClick={() => showSubmenu(false)} to={"/profile"}>
												<i className="icon-edit"></i> Edit Profile
											</Link>
										</li>
										{/* <li>
											<Link onClick={() => showSubmenu(false)} to={"/profile/my-plans"}>
												<i className="icon-planning"></i> Plan Details
											</Link>
										</li> */}
										{/* <li>
											<Link onClick={() => showSubmenu(false)} to={"/profile/family-members"}>
												<i className="icon-family"></i> Family Member
											</Link>
										</li> */}
										{/* <li>
											<Link onClick={() => showSubmenu(false)} to={"/additional-benefits/1"}>
												<i className="icon-additional"></i> Additional Benefits
											</Link>
										</li> */}
										{
											localStorage.getItem('isAdmin') === 'true' &&
											<li>
												<Link onClick={() => showSubmenu(false)} to={"/employees"}>
													<i className="icon-additional"></i> Change Employee Status
												</Link>
											</li>
										}
										{/* <li>
											<Link onClick={() => showSubmenu(false)} to={"/message-from-chairman"}>
												<i className="icon-chat"></i> Message from Chairman
											</Link>
										</li> */}
										{/* <li>
											<Link onClick={() => showSubmenu(false)} to={"/about-us"}>
												<i className="icon-user"></i> About Us
											</Link>
										</li> */}
										<li>
											<Link onClick={() => showSubmenu(false)} to={"/profile/change-password"}>
												<i className="icon-lock"></i> Change password
											</Link>
										</li>
										<li onClick={() => logOut(dispatch(logout()), navigate("/login"))} className="text-danger">
											<i className="icon-exit"></i> Signout
										</li>
									</ul>
								</div> : <div className="user-menu">
									<ul>
										<li>
											<a href={planDetailDoc} target="_blank" download=""><i className="icon-planning"></i> View User Plan</a>
											{/* <Link>
												<i className="icon-planning"></i> View User Plan
											</Link> */}
										</li>
										<li>
											<Link onClick={() => showSubmenu(false)} to={"/admin/all-providers"}>
												<i className="icon-id-card"></i> All Providers
											</Link>
										</li>
										<li>
											<Link onClick={() => showSubmenu(false)} to={"/admin/update-password"}>
												<i className="icon-lock"></i> Change password
											</Link>
										</li>
										<li onClick={() => adminLogout(navigate("/admin"))} className="text-danger">
											<i className="icon-exit"></i> Signout
										</li>
									</ul>
								</div>
							}

							{/* User Dropdown End */}
						</div>
						<a className="notification-link" onClick={() => Navigate(`/notifications`)}><img src={NotificationIcon} alt="notification" title="notification" height={50} width={50} /></a>
					</div>
					{/* User Profile End */}
				</div>
			</header>
		</>
	);
};
export default Header;
