import React from "react";
import Loader from "../components/Loader";

const PopupImportFile = ({
    showFileInput,
    showPopup,
    closePopUpHandler,
    handleFileChange,
    handleFileUpload,
    selectedFile,
    setShowFileInput,
    DownloadFileHandler,
    showLoader
}) => {
    return (
        <>
            {showPopup && (
                <div className="popup" id="popup-1">
                    <div className="overlay"></div>
                    <div className="content">
                        <button onClick={closePopUpHandler} className="close-btn">
                            &times;
                        </button>
                        <h3 className="heading">Import Employee Records</h3>
                        <div className="main-content">
                    <Loader show={showLoader} />
                            <div className="main-content-top">
                                <div>
                                    <i className="icon-cloud-upload"></i>
                                </div>
                                <h6 className="file-heading">Upload File</h6>
                                <p className="file-text">
                                    Maximum file size: 100MB Accepted formats: .csv
                                </p>
                                <div className="browsefile-div">
                                   <div className="input-browse-file">
                                        <button
                                            className="browse-file-btn fw-300"
                                            onClick={() => setShowFileInput(true)}
                                        >
                                            Browse File
                                        </button>

                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileChange}
                                        />
                                   </div>
                              
                                    {selectedFile && (
                                        <p className="selected-file-name">
                                            {selectedFile.name}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="main-content-bottom">
                                <p>
                                    This section allows you to import bulk employee records from a CSV file.
                                </p>
                                <p>
                                    For a clean import, the CSV must include the header and adhere to the format as indicated in our sample file. All the stated columns are mandatory for the import. Duplicate entries will be skipped during the import.
                                </p>
                                <div className="download-file">
                                    <button className="fw-300" onClick={DownloadFileHandler}>
                                        <i className="icon-download download-icon"></i>
                                        <span className=""></span>
                                        Download Sample File
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="footer-content">
                            <button className="btn btn-primary" onClick={handleFileUpload}>Verify and Import</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupImportFile;
