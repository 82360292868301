import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

// Router Authentication restrictions
export const PrivateRoute = () => {
	let auth = false;
	localStorage.getItem("encore-auth-token") && localStorage.getItem("encore-auth-token") != "undefined" ? (auth = localStorage.getItem("encore-auth-token")) : (auth = false);
	return auth ? (
		<>
			<Header />
			<Sidebar />
			<Outlet />
			<Footer />
		</>
	) : (
		<Navigate to="/login" />
	);
};



