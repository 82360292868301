import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  getResourceDocumentsList,
  listOfAdditionalFacility,
} from "../axios/apicall";
import Loader from "../components/Loader";

const EditHomescreenCardModal = ({
  onClose,
  onSubmit,
  currentCardData,
  internalRedirectRecords,
}) => {
  const [data, setData] = useState({
    id: "",
    title: "",
    link_type: "internal",
    redirect: "",
    visibleTo: "All",
    isActive: false,
    redirectId: null,
  });
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const fileInputRef = useRef(null);
  const [redirectId, setRedirectId] = useState(null);
  const [redirectOptions, setRedirectOptions] = useState([]);
  const [redirectKeyword, setRedirectKeyword] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (currentCardData) {
      setData({
        id: currentCardData.id || "",
        title: currentCardData.title || "",
        link_type: currentCardData.link_type || "internal",
        redirect: currentCardData.redirect || "",
        visibleTo: currentCardData.visibleTo || "Pre-Enrolled",
        isActive: currentCardData.isActive ?? true,
        redirect_id: currentCardData.redirect_id,
      });
      setRedirectKeyword({
        value: currentCardData.redirect,
        label: currentCardData.redirect,
      });

      const options = internalRedirectRecords.map((record) => {
        return { value: record.id, label: record.title };
      });
      if (options) setRedirectOptions(options);

      const redirectIdRecord = internalRedirectRecords.find(
        (record) => record.id === currentCardData.redirect_id
      );

      const transformedRedirectIdRecord =
        redirectIdRecord && currentCardData.redirect === "EDUCATION"
          ? { ...redirectIdRecord, title: redirectIdRecord.fileName }
          : redirectIdRecord;

      if (redirectIdRecord)
        setRedirectId({
          value: transformedRedirectIdRecord.id,
          label: transformedRedirectIdRecord.title,
        });
    }
  }, [currentCardData]);

  const linkTypeOptions = [
    { value: "internal", label: "Internal" },
    { value: "external", label: "External" },
  ];

  const visibilityOptions = [
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "All", label: "All" },
  ];

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name, selectedOption) => {
    setData({ ...data, [name]: selectedOption.value });
  };

  const handleLinkTypeChange = (name, selectedOption) => {
    setData({ ...data, [name]: selectedOption.value });
  };
  

  const handleSubmit = () => {
    const redirectInput =
      data.link_type === "internal" ? redirectKeyword?.value : data.redirect;
    onSubmit({
      ...data,
      imageFile,
      redirect: redirectInput,
      redirect_id: redirectId?.value,
    });
    onClose();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageName(file.name);
    }
  };

  const handleButtonClick = () => {
    setShowFileInput(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  // Toggle function for Active/Inactive state
  const toggleActive = () => {
    setData((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  const internalRedirectOptions = [
    { value: "HOME", label: "HOME" },
    { value: "CARE_PROGRAMS", label: "CARE PROGRAMS" },
    { value: "ID_CARD", label: "ID CARD" },
    { value: "EDUCATION", label: "EDUCATION" },
    { value: "IN_NETWORK_DOCTORS", label: "IN NETWORK DOCTORS" },
    { value: "PLAN_DETAILS", label: "PLAN DETAILS" },
    { value: "DEPENDENT", label: "DEPENDENT" },
    { value: "MESSAGE_FROM_CHAIRMAN", label: "MESSAGE FROM CHAIRMAN" },
    { value: "MY_PROFILE", label: "MY PROFILE" },
    { value: "GENERAL", label: "GENERAL" },
  ];

  const handleRedirectKeywordChange = async (selectedOption) => {
    setRedirectKeyword(selectedOption);
    setRedirectId(null);

    if (selectedOption.value === "CARE_PROGRAMS") {
      setShowLoader(true);
      try {
        const response = await listOfAdditionalFacility();
        setShowLoader(false);
        if (response.data.status === "success") {
          setRedirectOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.title,
            }))
          );
        }
      } catch (error) {
        setShowLoader(false);
        toast.error("Error fetching CARE PROGRAMS data");
      }
    } else if (selectedOption.value === "EDUCATION") {
      setShowLoader(true);
      try {
        const response = await getResourceDocumentsList({});
        setShowLoader(false);
        if (response.data.status === "success") {
          setRedirectOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.fileName,
            }))
          );
        }
      } catch (error) {
        setShowLoader(false);
        toast.error("Error fetching EDUCATION data");
      }
    }
  };

  return (
    <div className="popup">
      <div className="content">
        <Loader show={showLoader} />
        <button onClick={onClose} className="close-btn">
          &times;
        </button>
        <h3 className="popup-header">Edit Item</h3>
        <div className="main-content">
          {/* Updated Active/Inactive toggle */}
          <div className="change-alert-status mb-2">
            <span>Status:</span>
            <div className="d-flex ml-1">
              <button
                className={`left ${data.isActive ? "active" : "in-active"}`}
                onClick={() => toggleActive()}
              >
                Active
              </button>
              <button
                className={`right ${!data.isActive ? "active" : "in-active"}`}
                onClick={() => toggleActive()}
              >
                Inactive
              </button>
            </div>
          </div>

          <input
            type="text"
            name="title"
            value={data.title}
            onChange={handleChange}
            placeholder="Title"
            className="form-control mb-2"
          />
          <Select
            className="dropdown mb-2"
            options={linkTypeOptions}
            value={linkTypeOptions.find(
              (option) => option.value === data.link_type
            )}
            onChange={(selectedOption) => handleLinkTypeChange("link_type", selectedOption)}
            isSearchable={false}
            placeholder="Select Link Type"
            required
          />
          {data.link_type === "external" && (
            <input
              type="text"
              name="redirect"
              value={data.redirect}
              onChange={handleChange}
              placeholder="Redirect URL"
              className="form-control mb-2"
            />
          )}
          {data.link_type && data.link_type === "internal" && (
            <div>
              <Select
                className="dropdown mb-2"
                options={internalRedirectOptions}
                value={redirectKeyword}
                onChange={handleRedirectKeywordChange}
                placeholder="Select redirect keyword"
                required
              />
            </div>
          )}
          {data.link_type && data.link_type === "internal" && redirectKeyword &&
            (redirectKeyword.value === "CARE_PROGRAMS" ||
              redirectKeyword.value === "EDUCATION") && (
              <div>
                <Select
                  className="dropdown mb-2"
                  options={redirectOptions}
                  value={redirectId}
                  onChange={setRedirectId}
                  placeholder="Select redirect option"
                  required
                />
              </div>
            )}
          <Select
            className="dropdown mb-2"
            options={visibilityOptions}
            value={visibilityOptions.find(
              (option) => option.value === data.visibleTo
            )}
            onChange={(selectedOption) =>
              handleSelectChange("visibleTo", selectedOption)
            }
            isSearchable={false}
            placeholder="Visible To"
            required
          />
          <div>
            <button
              className="main-content-file-upload"
              onClick={handleButtonClick}
            >
              <span className="file-heading">
                <i className="icon-cloud-upload"></i> Click here Update Icon
              </span>
              {imageName && (
                <p className="file-name-text">Selected file: {imageName}</p>
              )}
            </button>

            {showFileInput && (
              <input
                className="form-control image-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            )}
          </div>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditHomescreenCardModal;
