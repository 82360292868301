import { useRef, useState } from "react";
import Select from "react-select";
import {
  getResourceDocumentsList,
  listOfAdditionalFacility,
} from "../axios/apicall";
import Loader from "../components/Loader";

const AddNewHomescreenCardModal = ({ onClose, onSubmit }) => {
  const [newCard, setNewCard] = useState({
    title: "",
    link_type: "internal",
    redirect: "",
    visibleTo: "All",
    isActive: true,
    redirectId: null,
  });
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const fileInputRef = useRef(null);
  const [redirectId, setRedirectId] = useState(null);
  const [redirectOptions, setRedirectOptions] = useState([]);
  const [redirectKeyword, setRedirectKeyword] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (e) => {
    setNewCard({ ...newCard, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name, selectedOption) => {
    setNewCard({ ...newCard, [name]: selectedOption.value });
  };

  const handleSubmit = () => {
    const redirectInput =
      newCard.link_type === "internal"
        ? redirectKeyword?.value
        : newCard.redirect;
    onSubmit({
      ...newCard,
      imageFile,
      redirect: redirectInput,
      redirect_id: redirectId?.value,
    });
    onClose();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageName(file.name);
    }
  };

  const handleButtonClick = () => {
    setShowFileInput(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input value to allow re-selection of the same file
      fileInputRef.current.click(); // Trigger input click to open file dialog
    }
  };

  const toggleActive = () => {
    setNewCard((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  const linkTypeOptions = [
    { value: "internal", label: "Internal" },
    { value: "external", label: "External" },
  ];

  const visibilityOptions = [
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "All", label: "All" },
  ];

  const internalRedirectOptions = [
    { value: "HOME", label: "HOME" },
    { value: "CARE_PROGRAMS", label: "CARE PROGRAMS" },
    { value: "ID_CARD", label: "ID CARD" },
    { value: "EDUCATION", label: "EDUCATION" },
    { value: "IN_NETWORK_DOCTORS", label: "IN NETWORK DOCTORS" },
    { value: "PLAN_DETAILS", label: "PLAN DETAILS" },
    { value: "DEPENDENT", label: "DEPENDENT" },
    { value: "MESSAGE_FROM_CHAIRMAN", label: "MESSAGE FROM CHAIRMAN" },
    { value: "MY_PROFILE", label: "MY PROFILE" },
    { value: "GENERAL", label: "GENERAL" },
  ];

  const handleRedirectKeywordChange = async (selectedOption) => {
    setRedirectKeyword(selectedOption);
    setRedirectId(null);
    setRedirectOptions([]);

    if (selectedOption.value === "CARE_PROGRAMS") {
      setShowLoader(true);
      try {
        const response = await listOfAdditionalFacility();
        setShowLoader(false);
        if (response.data.status === "success") {
          setRedirectOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.title,
            }))
          );
        }
      } catch (error) {
        setShowLoader(false);
        toast.error("Error fetching CARE PROGRAMS data");
      }
    } else if (selectedOption.value === "EDUCATION") {
      setShowLoader(true);
      try {
        const response = await getResourceDocumentsList({});
        console.log({ response });
        setShowLoader(false);
        if (response.data.status === "success") {
          setRedirectOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.fileName,
            }))
          );
        }
      } catch (error) {
        console.log({ error });
        setShowLoader(false);
        toast.error("Error fetching EDUCATION data");
      }
    }
  };

  return (
    <div className="popup">
      <div className="content">
        <Loader show={showLoader} />
        <button onClick={onClose} className="close-btn">
          &times;
        </button>
        <h3 className="popup-header">Add New Item</h3>
        <div className="change-alert-status mb-2">
          <span>Status:</span>
          <div className="d-flex ml-1">
            <button
              className={`${newCard.isActive ? "active" : "in-active"} left`}
              onClick={() => toggleActive()} // Set to active on click
            >
              Active
            </button>
            <button
              className={`${!newCard.isActive ? "active" : "in-active"} right`}
              onClick={() => toggleActive()} // Set to inactive on click
            >
              In Active
            </button>
          </div>
        </div>
        <div className="main-content">
          <input
            type="text"
            name="title"
            value={newCard.title}
            onChange={handleChange}
            placeholder="Title"
            className="form-control mb-2"
          />
          <Select
            className="dropdown mb-2"
            options={linkTypeOptions}
            value={linkTypeOptions.find(
              (option) => option.value === newCard.link_type
            )}
            onChange={(selectedOption) =>
              handleSelectChange("link_type", selectedOption)
            }
            isSearchable={false}
            placeholder="Select Link Type"
            required
          />
          {newCard.link_type === "external" && (
            <input
              type="text"
              name="redirect"
              value={newCard.redirect}
              onChange={handleChange}
              placeholder="Redirect URL"
              className="form-control mb-2"
            />
          )}
          {newCard.link_type && newCard.link_type === "internal" && (
            <div>
              <Select
                className="dropdown mb-2"
                options={internalRedirectOptions}
                value={redirectKeyword}
                onChange={handleRedirectKeywordChange}
                placeholder="Select redirect keyword"
                required
              />
            </div>
          )}
          {redirectKeyword &&
            (redirectKeyword.value === "CARE_PROGRAMS" ||
              redirectKeyword.value === "EDUCATION") && (
              <div>
                <Select
                  className="dropdown mb-2"
                  options={redirectOptions}
                  value={redirectId}
                  onChange={setRedirectId}
                  placeholder="Select redirect option"
                  required
                />
              </div>
            )}
          <Select
            className="dropdown mb-2"
            options={visibilityOptions}
            value={visibilityOptions.find(
              (option) => option.value === newCard.visibleTo
            )}
            onChange={(selectedOption) =>
              handleSelectChange("visibleTo", selectedOption)
            }
            isSearchable={false}
            placeholder="Visible To"
            required
          />
          <div>
            <button
              className="main-content-file-upload"
              onClick={handleButtonClick}
            >
              <span className="file-heading">
                <i className="icon-cloud-upload"></i> Click here to Upload Icon
              </span>
              {imageName && (
                <p className="file-name-text">Selected file: {imageName}</p>
              )}
            </button>

            {showFileInput && (
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept="image/*"
                ref={fileInputRef} // Assign the ref to the input element
                required
              />
            )}
          </div>
        </div>
        <div className="popup-footer">
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewHomescreenCardModal;
