import "../assets/SCSS/pages/dynamic-alert.scss";
import { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import Loader from "../components/Loader";
import {
  addNewAlert,
  deleteAlert,
  editAlert,
  getAlerts,
  updateCardSequence,
} from "../axios/apicall";
import { toast } from "react-toastify";
import AddNewAlertModal from "../components/AddNewAlertModal";
import EditAlertModal from "../components/EditAlertModal";
import Select from "react-select";

export const DynamicAlert = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [isAddNewAlertModalOpen, setIsAddNewAlertModalOpen] = useState(false);
  const [isEditAlertModalOpen, setIsEditAlertModalOpen] = useState(false);
  const [currentAlertData, setCurrentAlertData] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);
  const [filter, setFilter] = useState({});

  const fetchAlerts = async () => {
    setShowLoader(true);
    try {
      const response = await getAlerts({ ...filter });
      if (response.data.status === "success") {
        setAlerts(response.data.data.alerts);
      } else {
        toast.error("Failed to fetch alerts");
      }
    } catch (error) {
      toast.error("Error fetching alerts");
    } finally {
      setShowLoader(false);
    }
  };

  const handleDeleteAlert = async (id) => {
    setShowLoader(true);
    try {
      const response = await deleteAlert(id);
      if (response.data.status === "success") {
        toast.success("Deleted alert successfully");
        const alertsUpdated = alerts.filter((record) => record.id !== id);
        setAlerts(alertsUpdated);
      } else {
        toast.error(`Failed to delete alert, error: ${response.data.message}`);
      }
    } catch (error) {
      toast.error("Error deleting alert");
    } finally {
      setShowLoader(false);
    }
  };

  // Updated deleteAlertHandler with SweetAlert2 confirmation
  const deleteAlertHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this alert?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAlert(id); // Call the delete function if confirmed
      }
    });
  };

  const addNewAlertHandler = async (alertData) => {
    setShowLoader(true);
    try {
      const response = await addNewAlert(alertData);
      if (response.data.status === "success") {
        toast.success("Added new alert successfully");
        fetchAlerts(); // Refresh the alert list after adding
      } else {
        toast.error(`Failed to add alert, error: ${response.data.message}`);
      }
    } catch (error) {
      toast.error("Error adding alert, error: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const editCard = async (editAlertData) => {
    setShowLoader(true);
    try {
      const response = await editAlert(editAlertData);
      if (response.data.status === "success") {
        toast.success("Updated alert successfully");
        fetchAlerts(); // Refresh the alert list after adding
      } else {
        toast.error(`Failed to update alert, error: ${response.data.message}`);
      }
    } catch (error) {
      toast.error("Error updating alert, error: ", response.data.message);
    } finally {
      setShowLoader(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    fetchAlerts();
  }, [filter]);

  const editAlertHandler = (data) => {
    setIsEditAlertModalOpen(true);
    setCurrentAlertData(data);
  };

  // Drag and Drop handlers
  const handleDragStart = (e, record) => {
    setDraggedItem(record);
    e.currentTarget.style.opacity = "0.4";
    e.dataTransfer.effectAllowed = "move";
  };
  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
    e.currentTarget.classList.remove("over");
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  };
  const handleDragEnter = (e) => {
    e.currentTarget.classList.add("over");
  };
  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove("over");
  };
  const handleDrop = async (e, targetRecord) => {
    e.preventDefault();
    e.currentTarget.classList.remove("over");

    if (!draggedItem || draggedItem.id === targetRecord.id) return;
    const dragIndex = alerts.findIndex((item) => item.id === draggedItem.id);
    const dropIndex = alerts.findIndex((item) => item.id === targetRecord.id);
    // Create new array with reordered items
    const newCards = [...alerts];
    newCards.splice(dragIndex, 1);
    newCards.splice(dropIndex, 0, draggedItem);
    // Update sequence numbers
    const updatedCards = newCards.map((card, index) => ({
      ...card,
      sequence: index + 1,
    }));
    setAlerts(updatedCards);
    // Send sequence update to backend
    try {
      setShowLoader(true);
      const sequenceData = updatedCards.map((card) => ({
        id: card.id,
        seqNo: card.sequence,
      }));
      const response = await updateCardSequence({ sequenceData }, "alert");

      if (response.data.status === "success") {
        toast.success("Card sequence updated successfully");
      } else {
        toast.error("Failed to update card sequence");
        fetchAlerts();
      }
    } catch (error) {
      toast.error("Error updating card sequence");
      fetchAlerts();
    } finally {
      setShowLoader(false);
    }
  };
  const userTypeOptions = [
    { value: null, label: "All" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "Pre-Enrolled", label: "Pre-Enrolled" },
  ];

  const statusOptions = [
    { value: null, label: "All" },
    { value: true, label: "Active" },
    { value: false, label: "In-Active" },
  ];

  document.title = "Alerts";

  return (
    <>
      <main className="page">
        <div className="flex justify-between mb-3">
          <h3 className="mb-0 text-primary">Dynamic Alert</h3>
          <div className="flex justify-between">
            <div className="user-type">
              <Select
                className="dropdown"
                options={userTypeOptions}
                placeholder="Select User Type"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    visibleTo:
                      e.value === "Enrolled" || e.value === "Pre-Enrolled"
                        ? e.value
                        : null,
                  });
                }}
              />
            </div>
            <div className="status">
              <Select
                className="dropdown"
                options={statusOptions}
                placeholder="Select Status"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    isActive:
                      e.value === true || e.value === false ? e.value : null,
                  });
                }}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => setIsAddNewAlertModalOpen(true)} // Open modal on click
            >
              Add +
            </button>
          </div>
        </div>
        <div className="row align-start">
          <div className="content full">
            <Loader show={showLoader} />
            <div className="table-wrapper">
              <table className="alert-table table table-hover">
                <thead>
                  <tr>
                    <th className="bg-primary text-white">Description</th>
                    <th className="bg-primary text-white">Visible To</th>
                    <th className="bg-primary text-white">Status</th>
                    <th className="bg-primary text-white">Created At</th>
                    <th className="bg-primary text-white">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.map((record) => (
                    <tr
                      key={record.id}
                      draggable={true}
                      onDragStart={(e) => handleDragStart(e, record)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, record)}
                      className="cursor-move"
                    >
                      <td>{record.description}</td>
                      <td>{record.visibleTo}</td>
                      <td>
                        <span
                          className={
                            record.isActive
                              ? "is-active-true"
                              : "is-active-false"
                          }
                        >
                          {record.isActive ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td>{formatDate(record.createdAt)}</td>
                      <td>
                        <div className="flex align-center justify-center">
                          <button
                            className="btn-icon"
                            onClick={() => editAlertHandler(record)} // Open modal on click
                          >
                            <i className="icon-edit"></i>
                          </button>
                          <button
                            className="btn-icon"
                            onClick={() => deleteAlertHandler(record.id)}
                          >
                            <i className="icon-delete"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      {isAddNewAlertModalOpen && (
        <AddNewAlertModal
          tModal
          onClose={() => setIsAddNewAlertModalOpen(false)} // Close modal handler
          onSubmit={addNewAlertHandler} // Submit handler
        />
      )}

      {isEditAlertModalOpen && (
        <EditAlertModal
          onClose={() => setIsEditAlertModalOpen(false)} // Close modal handler
          onSubmit={editCard} // Submit handler
          currentAlertData={currentAlertData}
        />
      )}
    </>
  );
};
